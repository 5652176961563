import React, { Component } from "react"

import Item from "./item"
import getActiveItem from "../../utils/sidebar/get-active-item"
import getActiveItemParents from "../../utils/sidebar/get-active-item-parents"
import presets, { colors } from "../../utils/presets"
import { scale, options } from "../../utils/typography"
import Button from "../button"
import ExpandAllButton from "./button-expand-all"
import { Link } from "gatsby";
import logo from "../../images/logo/weadmin_logon.png"

// Access to global `localStorage` property must be guarded as it
// fails under iOS private session mode.
var hasLocalStorage = true
var testKey = `gatsbyjs.sidebar.testKey`
var ls
try {
  ls = global.localStorage
  ls.setItem(testKey, `test`)
  ls.removeItem(testKey)
} catch (e) {
  hasLocalStorage = false
}

const isItemActive = (activeItemParents, item) => {
  if (activeItemParents) {
    for (let parent of activeItemParents) {
      if (parent === item.title) return true
    }
  }
  return false
}

const getOpenItemHash = (itemList, state) => {
  for (let item of itemList) {
    if (item.items) {
      state.openSectionHash[item.title] =
        isItemActive(state.activeItemParents, item) ||
        state.activeItemLink.title === item.title

      getOpenItemHash(item.items, state)
    }
  }

  return false
}

class SidebarBody extends Component {
  constructor(props, context) {
    super(props, context)

    this._toggleSection = this._toggleSection.bind(this)
    this.state = { ...this._getInitialState(props) }
    this.scrollRef = React.createRef()
  }

  componentDidMount() {
    const node = this.scrollRef.current

    if (hasLocalStorage) {
      const key = this.props.itemList[0].key
      const initialState = this.state
      const localState = this._readLocalStorage(key)

      if (localState) {
        const bar = Object.keys(initialState.openSectionHash).filter(function(
          key
        ) {
          return initialState.openSectionHash[key]
        })

        const state = {
          ...initialState,
          openSectionHash: JSON.parse(localState).openSectionHash,
        }

        for (let item in initialState.openSectionHash) {
          for (let parent of bar) {
            if (parent === item) {
              state.openSectionHash[item] = true
            }
          }
        }

        state.expandAll = Object.entries(state.openSectionHash).every(k => k[1])
        this.setState(state, () => {
          if (node && this.props.position) {
            node.scrollTop = this.props.position
          }
        })
      } else {
        this._writeLocalStorage(this.state, key)
      }
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (props.activeItemHash !== state.activeItemHash) {
      const activeItemLink = getActiveItem(
        props.itemList,
        props.location,
        props.activeItemHash
      )

      return {
        activeItemLink: activeItemLink,
        activeItemParents: getActiveItemParents(
          props.itemList,
          activeItemLink,
          []
        ),
        activeItemHash: props.activeItemHash,
      }
    }

    return null
  }

  _getInitialState(props) {
    const activeItemLink = getActiveItem(
      props.itemList,
      props.location,
      props.activeItemHash
    )

    let defaultOpenParents = []
    if (props.itemList[0].key === 'prod') {
      defaultOpenParents = ["功能介绍"]
    }

    const state = {
      openSectionHash: {},
      expandAll: false,
      key: props.itemList[0].key,
      activeItemHash: props.activeItemHash,
      activeItemLink: activeItemLink,
      activeItemParents: getActiveItemParents(
        props.itemList,
        activeItemLink,
        defaultOpenParents
      ),
    }

    getOpenItemHash(props.itemList, state)
    state.expandAll = Object.entries(state.openSectionHash).every(k => k[1])

    return state
  }

  _readLocalStorage(key) {
    if (hasLocalStorage) {
      return localStorage.getItem(`gatsbyjs:sidebar:${key}`)
    } else {
      return false
    }
  }

  _writeLocalStorage(state, key) {
    if (hasLocalStorage) {
      localStorage.setItem(`gatsbyjs:sidebar:${key}`, JSON.stringify(state))
    }
  }

  _toggleSection(item) {
    const { openSectionHash } = this.state

    const state = {
      openSectionHash: {
        ...openSectionHash,
        [item.title]: !openSectionHash[item.title],
      },
    }

    state.expandAll = Object.entries(state.openSectionHash).every(k => k[1])

    this._writeLocalStorage(state, this.state.key)
    this.setState(state)
  }

  _expandAll = () => {
    if (this.state.expandAll) {
      this._writeLocalStorage(
        { openSectionHash: this._getInitialState(this.props).openSectionHash },
        this.state.key
      )
      this.setState({
        ...this._getInitialState(this.props),
        expandAll: false,
      })
    } else {
      let openSectionHash = { ...this.state.openSectionHash }
      Object.keys(openSectionHash).forEach(k => (openSectionHash[k] = true))
      this._writeLocalStorage({ openSectionHash }, this.state.key)
      this.setState({ openSectionHash, expandAll: true })
    }
  }

  render() {
    const { closeSidebar, itemList, location, onPositionChange, position, positionAlign } = this.props
    const { openSectionHash, activeItemLink, activeItemParents } = this.state
    const isExpand = location.pathname.indexOf('/case/') === 0 || location.pathname.indexOf('/product/') === 0
    const isRight = positionAlign === 'right' ? true : false
    return (
      <section
        aria-label="Secondary Navigation"
        id="SecondaryNavigation"
        className="docSearch-sidebar"
        css={{ height: `100%` }}
      >
        {!itemList[0].disableExpandAll && (
          <header css={{
            ...styles.utils,
            [presets.Phablet]: {
              display: `none`
            }
          }}>
            <Link to="/" css={{...scale(-2 / 3), color: '#0a75c2', lineHeight: '23px', [presets.Phablet]: {display: `none`}}}><img src={logo} css={{height: 20, paddingRight: 10, marginBottom: 0, marginTop: -3}} alt="" />返回主页</Link>
            {/* {isExpand && (<ExpandAllButton
              onClick={this._expandAll}
              expandAll={this.state.expandAll}
            />)} */}
          </header>
        )}
        <nav
          onScroll={({ nativeEvent }) => {
            // get proper scroll position
            const position = nativeEvent.target.scrollTop
            const { pathname } = location
            const sidebarType = pathname.split(`/`)[1]

            requestAnimationFrame(() => {
              onPositionChange(sidebarType, position)
            })
          }}
          ref={this.scrollRef}
          css={[{
            ...styles.sidebarScrollContainer,
            height: `calc(100% - ${presets.sidebarUtilityHeight})`,
            borderRight: isRight ? `none` : `1px solid ${colors.ui.border}`,
            // borderLeft: isRight ? `1px solid ${colors.ui.border}` : 'none',
            // height: itemList[0].disableExpandAll
            //   ? `100%`
            //   : `calc(100% - ${presets.sidebarUtilityHeight})`,
            [presets.Tablet]: {
              ...styles.sidebarScrollContainerTablet,
              height: `100%`,
            },
          }, isRight && styles.rightSidebar]}
        >
          <ul css={[styles.list, isRight && styles.rightList]}>
            {itemList.map((item, index) => (
              <Item
                activeItemLink={activeItemLink}
                activeItemParents={activeItemParents}
                isActive={openSectionHash[item.title]}
                item={item}
                key={index}
                level={0}
                location={location}
                isRight={isRight}
                onLinkClick={closeSidebar}
                onSectionTitleClick={this._toggleSection}
                openSectionHash={openSectionHash}
                expandButton={index === 0 && isExpand && (<ExpandAllButton
                  onClick={this._expandAll}
                  expandAll={this.state.expandAll}
                  overrideCSS={{
                    position: 'absolute',
                    top: 0,
                    right: 0,
                    zIndex: 1,
                    lineHeight: '22px',
                    paddingTop: 10,
                    paddingBottom: 10,
                    [presets.Phablet]: {
                      lineHeight: '20px',
                    }
                  }}
                />)}
              />
            ))}
          </ul>
          { positionAlign !== 'right' ?
            <div css={{ paddingLeft: 40 }}>
              <Button small to="/online/" overrideCSS={{
                width: 120,
                marginBottom: 15,
                justifyContent: 'center',
                [presets.Tablet]: {
                  width: 145,
                }
              }} style={{textDecoration: 'none'}}>
                  在线体验 →
              </Button>
              <div css={{ marginLeft: `auto` }}>
                  <label css={{ position: `relative` }}>
                      <Button small to="/download/" overrideCSS={{
                        width: 120,
                        marginTop: `0rem`,
                        marginLeft: `0rem`,
                        justifyContent: 'center',
                        [presets.Tablet]: {
                          width: 145,
                        }
                      }} style={{textDecoration: 'none'}}>
                          资料与软件下载 →
                      </Button>
                  </label>
              </div>
              <div className="help_bg">
                <div css={styles.help_title}>服务与支持</div>
                <div css={{...styles.help}}>服务热线:</div>
                <div css={styles.help}>135 2083 0552</div>
                <div css={{...styles.help, marginTop: 15}}>服务时间:</div>
                <div css={styles.help}>星期一至星期五</div>
                <div css={styles.help}>9:00 AM - 6:00 PM</div>
              </div>
            </div>
          : null }
        </nav>
      </section>
    )
  }
}

export default SidebarBody

const styles = {
  utils: {
    borderRight: `1px solid ${colors.ui.border}`,
    display: `flex`,
    alignItems: `center`,
    height: presets.sidebarUtilityHeight,
    background: `white`,
    paddingLeft: 10,
    paddingRight: 8,
    borderBottom: `1px solid ${colors.ui.border}`,
  },
  sidebarScrollContainer: {
    WebkitOverflowScrolling: `touch`,
    background: `#fff`,
    border: 0,
    display: `block`,
    overflowY: `auto`,
    transition: `opacity 0.5s ease`,
    zIndex: 10,
    borderRight: `1px solid ${colors.ui.border}`,
    "::-webkit-scrollbar": {
      height: `6px`,
      width: `6px`,
    },
    "::-webkit-scrollbar-thumb": {
      background: colors.skyDark,
    },
    "::-webkit-scrollbar-thumb:hover": {
      background: `#1890FF`,
    },
    "::-webkit-scrollbar-track": {
      background: `white`,
    },
  },
  sidebarScrollContainerTablet: {
    backgroundColor: `white`,
    top: `calc(${presets.headerHeight} + ${presets.bannerHeight})`,
  },
  list: {
    margin: 0,
    paddingTop: 20,
    paddingBottom: 20,
    // fontSize: scale(-2 / 10).fontSize,
    fontSize: '14px',
    [presets.Tablet]: {
      // fontSize: scale(-4 / 10).fontSize,
      paddingBottom: 20,
    },
    "& a": {
      fontFamily: options.systemFontFamily.join(`,`),
    },
    "& li": {
      margin: 0,
      listStyle: `none`,
      position: 'relative'
    },
    "& > li:last-child > span:before": {
      display: `none`,
    },
  },
  help_title: {
    // fontSize: 16,
    marginBottom: 10,
    fontWeight: 600,
    fontSize: 14,
    [presets.Tablet]: {
      fontSize: 16,
      marginBottom: 18,
    },
  },
  help: {
    lineHeight: '21px',
    fontSize: scale(-2 / 10).fontSize,
    [presets.Tablet]: {
      fontSize: 14,
    },
  },
  rightSidebar: {
    background: '#f7f7f7',
    marginLeft: 24,
    borderRadius: 5,
    [presets.Tablet]: {
      background: '#f7f7f7',
    }
    // padding: '24px 24px 24px 0',
  },
  rightList: {
    height: '100%',
    background: '#f7f7f7',
    borderRadius: '5px',
  }
}
