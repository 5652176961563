import React from 'react'
import { Menu, Dropdown, Icon } from 'antd'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import colors from '../utils/colors'
import presets from "../utils/presets"
import LogoIcon from "../images/logo/weadminLogo.png"
import { vP, vPHd, vPVHd, vPVVHd } from "./gutters"
import typography, { rhythm, scale } from "../utils/typography"
// import QuickIcon from "../images/icon/quick-start1.png"
// import DwonloadIcon from "../images/icon/download1.png"
import QuickIcon2 from "../images/icon/quick-start2.png"
import DwonloadIcon2 from "../images/icon/download2.png"
import Faq from "../images/icon/faq-1.png"

// const navItemTopOffset = `0.5rem`
const navItemHorizontalSpacing = rhythm(1 / 4)

const assignActiveStyles = ({ isPartiallyCurrent }) => {
  return isPartiallyCurrent ? { style: styles.navItem.active } : {}
}


const NavItem = ({ linkTo, title, children, isActive }) => (
  <li css={styles.li}>
    <Link to={linkTo} getProps={assignActiveStyles} css={[styles.navItem, isActive && styles.activeLink ]} title={title}>
      {children}
    </Link>
  </li>
)

// const Header = ({ pathname, isMobile }) => {
class Header extends React.Component {
  render() {
    const { pathname, isMobile } = this.props;
    const isHomepage = pathname === '/'
  
    const socialIconsStyles = {
      ...styles.navItem,
      "&&": {
        "&:hover": {
          "&:after": {
            width: '0px',
          },
        },
      },
      // [presets.Phablet]: {
      //   color: isHomepage ? `white` : false,
      //   "&:hover": {
      //     color: `#1890FF`,
      //   },
      // },
      "@media (max-width: 910px)": {
        display: `none`,
        "@media (max-width: 692px)": {
          ...styles.navItem,
          "&:hover": {
            color: `#1890FF`,
          },
        }
      }
    }
  
    const SocialNavItem = ({ LinkTo, title, children, overrideCSS, isTarget }) => (
      isTarget ?
        <a
          href={LinkTo}
          title={title}
          target="_blank"
          rel="noopener noreferrer"
          css={{
            ...socialIconsStyles,
            ...overrideCSS,
          }}
        >
          {children}
        </a> :
        <Link
          to={LinkTo}
          title={title}
          css={{
            ...socialIconsStyles,
            ...overrideCSS,
          }}
        >
          {children}
        </Link>
    )
  
    // const socialNavOverrideCSS = {
    //   backgroundColor: '#FB2626',
    //   border: 0,
    //   color: '#fff',
    //   height: 28,
    //   lineHeight: '28px',
    //   borderRadius: '2px',
    //   padding: '0 10px',
    //   "&:hover": {
    //     color: `#fff`,
    //     opacity: 0.8,
    //   },
    //   "@media (max-width: 693px)": {
    //     border: 0,
    //     color: '#fff',
    //     height: 28,
    //     lineHeight: '28px',
    //     borderRadius: '2px',
    //     padding: '0 10px',
    //     "&:hover": {
    //       color: `#fff`,
    //       opacity: 0.8,
    //     },
    //   }
    // }
  
    const socialIconOverrideCSS = {
      padding: '0px 8px',
      lineHeight: '21px',
      borderBottom: 0,
      // color: isHomepage ? '#fff' : '#0a75c2',
      color: '#0a75c2',
      fontWeight: 500,
      "&:hover": {
        opacity: 0.8,
      },
      "@media (max-width: 692px)": {
        marginRight: `10px`,
        color: `#0a75c2`,
        padding: '0px 3px',
        [presets.Phablet]: {
        // color: isHomepage ? `black` :  `#0a75c2`,
        }
      }
    }
  
    const menu = (
      <Menu>
        <Menu.Item>
          <a target="_blank" rel="noopener noreferrer" href={"http://itossdemo.weadmin.com" + (isMobile ? '/mobile' : '')}>在线DEMO演示</a>
        </Menu.Item>
        <Menu.Item>
          {/* <a target="_blank" rel="noopener noreferrer" href="http://itossdemo.weadmin.com/webloader">老界面</a> */}
        </Menu.Item>
      </Menu>
    );
  
    return (
      <header css={{
        // backgroundColor: isHomepage ? `transparent` : `rgba(255,255,255,0.975)`,
        backgroundColor: `rgba(255,255,255,0.975)`,
        height: isHomepage ? '4.5rem' : presets.headerHeight,
        left: 0,
        // position: isHomepage ? `` : `relative`,
        // position: `relative`,
        right: 0,
        // top: isHomepage
        //   ? `calc(${presets.bannerHeight} + ${rhythm(
        //       options.blockMarginBottom
        //     )})`
        //   : presets.bannerHeight,
        top: presets.bannerHeight,
        zIndex: 99,
        position: `fixed`,
        "&:after": {
          content: `''`,
          position: `absolute`,
          bottom: 0,
          left: 0,
          right: 0,
          width: `100%`,
          height: 1,
          zIndex: -1,
          // background: isHomepage ? `transparent` : colors.ui.light,
        },
        [presets.Tablet]: {
          // position: isHomepage ? `` : `fixed`,
          position: `fixed`,
        },
        paddingLeft: `env(safe-area-inset-left)`,
        paddingRight: `env(safe-area-inset-right)`,
      }}>
        <div
          css={{
            ...styles.containerInner,
            ...(isHomepage
              ? {
                  paddingLeft: vP,
                  paddingRight: vP,
                  [presets.Hd]: {
                    paddingLeft: vPHd,
                    paddingRight: vPHd,
                  },
                  [presets.VHd]: {
                    paddingLeft: vPVHd,
                    paddingRight: vPVHd,
                  },
                  [presets.VVHd]: {
                    paddingLeft: vPVVHd,
                    paddingRight: vPVVHd,
                  },
                  // height: '4.5rem',
                  boxShadow: '0 4px 8px 0 rgba(40, 43, 49, 0.08)',
                }
              : {
                height: '4.5rem',
                boxShadow: '0 4px 8px 0 rgba(40, 43, 49, 0.08)',
              }),
            }}>
          <Link to="/" css={styles.logoLink}>
            <img src={LogoIcon} css={styles.logo} alt="" aria-hidden="true" />
          </Link>
          <nav
            className="navigation"
            aria-label="Primary Navigation"
            css={styles.navContainer}
          >
            <ul css={styles.ulContainer}>
              <li css={styles.li}>
                <Link to="/"  css={[styles.navItem, isHomepage && styles.activeLink]} style={ isHomepage ? { ...styles.navItem.active } : {} } title="首页">
                  首页
                </Link>
              </li>
              <NavItem linkTo="/product/" title="产品" isActive={ pathname.indexOf('/product/') > -1}>产品</NavItem>
              <NavItem linkTo="/solutions/" title="解决方案" isActive={ pathname.indexOf('/solutions/') > -1}>解决方案</NavItem>
              <NavItem linkTo="/free/" title="完全免费" isActive={ pathname.indexOf('/free/') > -1}>完全免费</NavItem>
              <NavItem linkTo="/quickstart/" title="新界面快速上手" isActive={ pathname.indexOf('/quickstart/') > -1}>新界面快速上手</NavItem> 
              <NavItem linkTo="/quick-start/" title="经典界面快速上手" isActive={ pathname.indexOf('/quick-start/') > -1}>经典界面快速上手</NavItem> 
              <NavItem linkTo="/online/" title="在线体验" isActive={ pathname.indexOf('/online/') > -1}>在线体验</NavItem>
              <NavItem linkTo="/download/" title="软件下载" isActive={ pathname.indexOf('/download/') > -1}>软件下载</NavItem>
              {/* <NavItem linkTo="/price/" title="报价体系" isActive={ pathname.indexOf('/price/') > -1}>报价体系</NavItem> */}
              <NavItem linkTo="/user-case/" title="客户案例" isActive={ pathname.indexOf('/user-case/') > -1}>客户案例</NavItem>
              {/* <NavItem linkTo="/case/" title="用户案例" isActive={ pathname.indexOf('/case/') > -1}>用户案例</NavItem> */}
              <NavItem linkTo="/FAQ/" title="FAQ库" isActive={ pathname.indexOf('/FAQ/') > -1}>FAQ库</NavItem>
              <NavItem linkTo="/about/" title="关于我们" isActive={ pathname.indexOf('/about/') > -1}>关于我们</NavItem>
              {/* <NavItem linkTo="/itsm/" title="ITSM" isActive={ pathname.indexOf('/itsm/') > -1}>ITSM</NavItem> */}
            </ul>
          </nav>
          {/* <div css={styles.markDown}>
            <SocialNavItem LinkTo="/quick-start/" title="快速上手" overrideCSS={socialNavOverrideCSS}>
              <img src="" />
            </SocialNavItem>
            <SocialNavItem LinkTo="/download/" title="软件下载" overrideCSS={{
              ...socialNavOverrideCSS,
              backgroundColor: '#2AABBA'
            }}>
              <strong>软件下载</strong>
            </SocialNavItem>
            <SocialNavItem LinkTo="https://ecc.weadmin.com" title="DEMO演示" isTarget={true} overrideCSS={{
              ...socialNavOverrideCSS,
              backgroundColor: '#2AA43E',
              marginRight: 0
            }}>
              <strong>DEMO演示</strong>
            </SocialNavItem>
          </div> */}
          <div css={{
            ...styles.markDown,
            "@media (max-width: 1020px)": {
              flexDirection: 'column',
              alignItems: 'flex-end'
            }
          }}>
            <div>
              <a
                href="javascript:;"
                title={"联系方式"}
                rel="noopener noreferrer"
                css={{
                  ...socialIconsStyles,
                  ...socialIconOverrideCSS,
                  marginTop: 5,
                  cursor: 'default',
                  "&:hover": {
                    color: '#0a75c2',
                    opacity: 0.8
                  },
                  "@media (max-width: 693px)": {
                    fontSize: 12
                  },
                  "@media (max-width: 1020px)": {
                    marginTop: 0,
                    marginRight: 0
                  }
                }}
              >
                {/* <Icon type="phone" /> 135 2083 0552 */}
              </a>
            </div>
            <div css={{
              ...styles.markDown,
              flexDirection: 'row',
              "@media (max-width: 1020px)": {
                height: 'auto',
                marginTop: -3
              }
            }}>
              <SocialNavItem LinkTo="/quickstart/" title="新界面快速上手" overrideCSS={socialIconOverrideCSS}>
                <img css={styles.image1} src={isHomepage ? QuickIcon2 : QuickIcon2} alt="" />
                <img css={styles.image2} src={QuickIcon2} alt="" />
              </SocialNavItem>
              <SocialNavItem LinkTo="/download/" title="软件下载" overrideCSS={socialIconOverrideCSS}>
                <img css={styles.image1} src={isHomepage ? DwonloadIcon2 : DwonloadIcon2} alt="" />
                <img css={styles.image2} src={DwonloadIcon2} alt="" />
              </SocialNavItem>
              <SocialNavItem LinkTo="/FAQ/" title="FAQ库" overrideCSS={socialIconOverrideCSS}>
                <img css={styles.image1} src={isHomepage ? Faq : Faq} alt="" />
                <img css={styles.image2} src={Faq} alt="" />
              </SocialNavItem>
            </div>
             {/* <SocialNavItem LinkTo="http://itossdemo.weadmin.com/" title="在线DEMO" overrideCSS={{
              ...socialIconOverrideCSS,
              marginTop: 5,
              marginRight: 0,
              "&:hover": {
                // color: isHomepage ? '#fff' : '#0a75c2',
                color: '#0a75c2',
                opacity: 0.8
              },
              "@media (max-width: 693px)": {
                fontSize: 12
              }
            }}>
              DEMO 
            </SocialNavItem> */}
          </div>
        </div>
      </header>
    )
  }
}

const styles = {
  backGround: {
    background: '',
    height: presets.headerHeight,
    marginBottom: '0rem',
  },
  containerInner: {
    alignItems: `center`,
    display: `flex`,
    fontFamily: typography.options.headerFontFamily.join(`,`),
    height: `100%`,
    margin: `0 auto`,
    paddingLeft: rhythm(3 / 4),
    paddingRight: rhythm(3 / 4),
    width: `100%`,
  },
  li: {
    display: `block`,
    margin: 0,
    marginLeft: navItemHorizontalSpacing,
    marginRight: navItemHorizontalSpacing,
  },
  logo: {
    height: 28,
    margin: 0,
    [presets.Tablet]: {
      height: `2.33rem`,
    },
  },
  logoLink: {
    float: 'left',
    minWidth: 100,
    textDecoration: 'none',
  },
  markDown: {
    alignSelf: `flex-end`,
    display: `flex`,
    marginLeft: `auto`,
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%'
  },
  navContainer: {
    display: `none`,
    [presets.Phablet]: {
      display: `flex`,
      alignItems: `center`,
    },
  },
  navItem: {
    ...scale(-1 / 3),
    borderBottom: `0.125rem solid transparent`,
    color: `inherit`,
    display: `block`,
    letterSpacing: `0.03em`,
    // lineHeight: `calc(${presets.headerHeight} - ${navItemTopOffset})`,
    marginRight: '5px',
    position: `relative`,
    textDecoration: `none`,
    textTransform: `uppercase`,
    top: 0,
    transition: `color ${presets.animation.speedDefault} ${
      presets.animation.curveDefault
    }`,
    WebkitFontSmoothing: `antialiased`,
    zIndex: 1,
    fontSize: '14px',
    "&&": {
      padding: '0 3px',
      "&:after": {
        content: `''`,
        display: 'none',
        height: '2px',
        left: '0px',
        position: 'absolute',
        right: '0px',
        top: '100%',
        width: '0px',
        background: colors.skyDark,
        transition: 'all 0.25s ease',
      },
      "&:hover": {
        color: colors.skyDark,
        "&:after": {
          width: '100%',
        },
      },
    },
    [presets.Tablet]: {
      "&&": {
        "&:after": {
          display: 'inline-block',
        }
      }
    },
    active: {
      color: colors.skyDark,
    },
  },
  activeLink: {
    "&&": {
      color: colors.skyDark,
      "&:after": {
        width: '100%',
      },
    },
    "&:hover": {
      "&:after": {
        width: '100%',
      },
    },
  },
  ulContainer: {
    display: `none`,
    "@media (min-width: 693px)": {
      alignSelf: `flex-end`,
      display: `flex`,
      flexGrow: 1,
      listStyle: `none`,
      margin: 0,
      marginLeft: rhythm(3 / 4),
      marginTop: 5,
      maskImage: `linear-gradient(to right, transparent, white ${rhythm(
        1 / 8
      )}, white 98%, transparent)`,
      overflowX: `auto`,
    },
  },
  image1: {
    width: 16,
    height: 16,
    marginBottom: 0,
    "@media (max-width: 910px)": {
      display: `none`,
    }
  },
  image2: {
    width: 13,
    height: 13,
    marginBottom: 0,
    "@media (min-width: 693px)": {
      display: `none`,
    }
  }
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: '',
}

export default Header
