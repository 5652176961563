import React from 'react'
import Header from "./header"
import PropTypes from 'prop-types'
import { LocaleProvider, BackTop } from 'antd'
import presets from "../utils/presets"
import zhCN from 'antd/lib/locale-provider/zh_CN'
import PageWithSidebar from "../components/page-with-sidebar"
import typography, { rhythm } from "../utils/typography"

// import './css/layout.css'
import './css/animate.min.css'
import './css/app.css'
import MobileNavigation from './navigation-mobile';
import $ from "jquery"

let hmCount = 0;

class Layout extends React.Component {
  state = {
    isMobile: false
  }
  
  componentDidMount() {
    var links = document.links;
    for (var i = 0; i < links.length; i++) {
      if (links[i].hostname !== this.props.location.hostname && links[i].target !== '_blank') {
        links[i].target = '_blank';
      }
      const website = 'http://itossdemo.weadmin.com', mobilesite = 'http://itossdemo.weadmin.com/mobile';
      if ([website, website + '/', mobilesite, mobilesite + '/'].indexOf(links[i].href) > -1) {
        if ($(window).width() < 768) {
          links[i].href = 'http://itossdemo.weadmin.com/mobile';
        } else {
          links[i].href = 'http://itossdemo.weadmin.com';
        }
      }
    }
    if ($(window).width() < 768) {
      !this.state.isMobile && this.setState({ isMobile: true });
    } else {
      this.state.isMobile && this.setState({ isMobile: false });
    }
    
    // 加载百度统计
    if (!hmCount) {
      setTimeout(() => {
        var hm = document.createElement("script");
        hm.src = "https://hm.baidu.com/hm.js?65c6aa816df882715d64ed1b030c1184";
        document.body.appendChild(hm);
      }, 500)
      hmCount ++;
    }

    // 滑动效果
    var win = $(window);
    this.setFadeStatus(win.height(), 'fadeCard');
    this.setFadeStatus(win.height(), 'fadeCard2');
    const _this = this;
    $(window).scroll(function(){
      var wScrollTop = $(this).scrollTop();
      var wHeight = $(this).scrollTop()+$(this).height();
      _this.setFadeStatus(wHeight, 'fadeCard');
      _this.setFadeStatus(wHeight, 'fadeCard2');
    })
    $(window).on("resize", this.setDemoLink);
  }

  componentWillUnmount() {
    $(window).off("resize", this.setDemoLink);
  }

  setFadeStatus = (height, fade) => {
    let count = 0;
    $(`.${fade}.fade-hidden`).each((i, item) => {
      if(height > $(item).offset().top){
        if(!$(item).hasClass("fadeInUp")){
          const _delay = 0.5 * count;
          const _d = (_delay + '').replace('.', '');
          $(item).addClass(`delay-${_d}s`);
          $(item).addClass("fadeInUp");
          $(item).removeClass("fade-hidden");
          count ++;
          if (count > 2) {
            count = 0;
          }
        }
      }
    })
  }

  setDemoLink = () => {
    const sites = ['http://itossdemo.weadmin.com', 'http://itossdemo.weadmin.com/mobile']
    let _matchlink = sites[1], _editlink = sites[0];
    if ($(window).width() < 768) {
      _matchlink = sites[0];
      _editlink = sites[1];
      !this.state.isMobile && this.setState({ isMobile: true });
    } else {
      this.state.isMobile && this.setState({ isMobile: false });
    }
    var links = document.links
    for (var i = 0; i < links.length; i++) {
      if ([_matchlink, _matchlink + '/'].indexOf(links[i].href) > -1) {
        links[i].href = _editlink;
      }
    }
  }

  render() {
    const isSidebarDisabled = this.props.isSidebarDisabled || !this.props.itemList
    const isHomepage = this.props.location.pathname === `/`
    const hasRightSidebar = this.props.anchorMenu && this.props.anchorMenu.length ? true : false

    return (
      <div className={isHomepage ? `is-homepage` : ``} css={styles.layout}>
          <Header pathname={this.props.location.pathname} isMobile={this.state.isMobile} />

          <div css={{
            // paddingTop: presets.bannerHeight,
            paddingLeft: `env(safe-area-inset-left)`,
            paddingRight: `env(safe-area-inset-right)`,
            paddingTop: `calc(${presets.bannerHeight} + ${presets.headerHeight})`,
            [presets.Tablet]: {
              margin: `0 auto`,
              // paddingTop: isHomepage
              //   ? presets.bannerHeight
              //   : `calc(${presets.bannerHeight} + ${presets.headerHeight})`,
              paddingTop: `calc(${presets.bannerHeight} + ${presets.headerHeight})`
            },
          }}>
          <LocaleProvider locale={zhCN}>
            <>
              <PageWithSidebar
                contentTitle={this.props.contentTitle}
                disable={isSidebarDisabled}
                itemList={this.props.itemList}
                location={this.props.location}
                enableScrollSync={this.props.enableScrollSync}
                renderContent={() => this.props.children}
                hasRightSidebar={hasRightSidebar}
              />

              { hasRightSidebar ?
                <PageWithSidebar
                  positionAlign="right"
                  contentTitle={this.props.contentTitle}
                  disable={isSidebarDisabled}
                  itemList={this.props.anchorMenu}
                  location={this.props.location}
                  enableScrollSync={this.props.enableScrollSync}
                  renderContent={() => this.props.children}
                />
               : null }
              </>
            </LocaleProvider>

          <BackTop className="backtop" visibilityHeight={200} />
        </div>
        <MobileNavigation pathname={this.props.location.pathname} />
      </div>
    )
  }
}

const styles = {
  layout: {
    margin: 'auto 0',
    padding: '0rem 0rem 0rem',
    paddingTop: 0,
    paddingBottom: '2rem',
    [presets.Tablet]: {
      paddingBottom: '0rem'
    }
  },
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}
export default Layout
