import React from "react"
import { Icon, Row, Col } from "antd"
import { Link } from 'gatsby'
import styled from "@emotion/styled"
import typography from "../utils/typography"
import ARCode from "../images/footer/s-weixin.jpg"
import GABA from "../images/footer/gaba.png"
import presets, { colors } from "../utils/presets"

const Footer = (props) => (
  <>
  <Row css={styles.footer} type="flex" justify="space-around">
    <Col xl={6} lg={8} md={8} xs={24} css={styles.footerLink}>
      <Link to="/"><h2 css={styles.title}>WeADMIN</h2></Link>
      <Row type="flex" align="middle">
        <div style={{paddingRight: 12}}>
          <Link css={{...styles.info, ...styles.infoLink}} to="/product/ecc-it/function-monitor/">全方位的设备与应用监控</Link>
          <Link css={{...styles.info, ...styles.infoLink}} to="/product/ecc-it/function-weixin/">微运维管理 - 微信小程序</Link>
          <Link css={{...styles.info, ...styles.infoLink}} to="/product/ecc-it/function-topo/">立体拓朴展示</Link>
          <Link css={{
            ...styles.info,
            ...styles.infoLink,
            display: 'block',
            [presets.Tablet]: {
              display: 'none'
            }
          }} to="/about/">关于我们</Link>
        </div>
        <div>
          <img src={ARCode} style={{marginBottom: 0}} alt="WexinCode" />
        </div>
      </Row>
    </Col>
    <Col xl={6} lg={8} md={8} xs={24} css={styles.footerLink}>
      <div css={styles.line}><SubTitle>联系我们</SubTitle></div>
      <div css={{...styles.info, marginTop: -7}}><Icon type="phone" style={{marginRight: 5}} /> 135 2083 0552（商务咨询 微信同号）</div>
      <div css={styles.info}><Icon type="qq" style={{marginRight: 5}} /> 1群：300014916(群已满) </div>
      <div css={styles.info}><Icon type="qq" style={{marginRight: 5}} /> 2群：105953229 </div>
      <div css={styles.info}><Icon type="qq" style={{marginRight: 5}} /> 3群：513656800(群已满) </div>
      <div css={styles.info}><Icon type="qq" style={{marginRight: 5}} /> 4群：104897508(群已满) </div>
      <div css={styles.info}><Icon type="qq" style={{marginRight: 5}} /> 5群：541980259 </div>
      <div css={styles.info}><Icon type="qq" style={{marginRight: 5}} /> 6群：550290603(群已满) </div>
      <div css={styles.info}><Icon type="qq" style={{marginRight: 5}} /> 7群：832536727 </div>
      <div css={styles.info}><Icon type="mail" style={{marginRight: 5}} /> support@weadmin.com </div>
      <div css={styles.info}><Icon type="environment" style={{marginRight: 5}} /> 北京市海淀区丹棱街18号创富大厦19层</div>
      {/* <div css={styles.licence}>
        <div css={{marginBottom: '5px'}}>© 2022长沙游龙网网络科技有限公司版权所有</div>
        <div>京ICP备14004049-1号</div>
      </div> */}
    </Col>
    <Col xl={6} lg={8} md={8} xs={24} css={styles.footerLink}>
      <div style={styles.line}><SubTitle>版权所有</SubTitle></div>
      <div style={{...styles.info, marginTop: -7}}>北京游龙网网络科技有限公司</div>
      <div style={styles.info}>Copyright © 2022 北京游龙网网络科技有限公司 版权所有</div>
      <div style={styles.info}>备案号：<a href="https://beian.miit.gov.cn" target="_blank" title="京ICP备14004049-1号">京ICP备14004049号-4</a></div>
      <div style={styles.info}><img src={GABA} width="18" height="18" style={{"margin-top": 14}}  alt="公安备案图片"/><a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=43010302001396">湘公网安备 43010302001396号</a></div>
    </Col>
  </Row>
  </>
)

const SubTitle = styled(`h5`)`
  color: ${colors.ui.light};
  font-size: 14px;
  color: #fff;
  padding: 0 2px 6px 0;
  border-bottom: 3px solid #fff;
  display: inline-block;
  margin-bottom: -2px;

  ${presets.Tablet} {
    font-size: 20px;
  }
  ${presets.Desktop} {
    margin-left: 0rem;
    margin-bottom: -2px;
  }
`

const styles ={
  title: {
    fontSize: 21,
    lineHeight: '21px',
    color: '#fff',
    marginBottom: '0.55rem',
    marginTop: -9,
    letterSpacing: 1,
    [presets.Tablet]: {
      fontSize: 36,
      lineHeight: '48px',
    }
  },
  line: {
    borderBottom: '1px dotted #fff',
    marginBottom: '1rem',
    width: 300,
    maxWidth: '100%'
    // paddingBottom: 5,
    // backgroundColor: '#272727',
  },
  footer:{
    borderRadius: presets.radiusLg,
    display: `flex`,
    flex: `0 1 auto`,
    flexWrap: `wrap`,
    transform: `translateZ(0)`,
    width: `100%`,
    paddingBottom: '0.25rem',
    paddingTop: '2rem',
    padding: '2rem 20px 2rem',
    [presets.Tablet]: {
      paddingBottom: '0.25rem',
      paddingTop: '2rem',
      padding: '2rem 12px 0rem',
    }
  },
  footerLink: {
    color:colors.ui.light,
    marginBottom: '1.5rem'
    // float: "left",
    // margin: "0 24px 5px",
  },
  info: {
    // color: `aliceBlue`,
    display: 'block',
    color: "rgba(255, 255, 255, 1)",
    fontFamily: typography.options.headerFontFamily.join(`,`),
    padding: '0px 0rem',
    lineHeight: '28px',
    // fontSize: 12
  },
  infoLink: {
    textDecoration: 'underline',
    ":hover": {
      color: "rgba(255, 255, 255, 0.9)",
      textDecoration: 'underline',
    },
    ":focus, :active": {
      textDecoration: 'underline',
    }
  },
  licence: {
    // color: `aliceBlue`,
    color: "rgba(255, 255, 255, 1)",
    fontFamily: typography.options.headerFontFamily.join(`,`),
    fontSize: `0.5rem`,
    marginLeft: `auto`,
    textAlign: `right`,
    marginTop: '2.15rem',
  },
  link: {
    color: `aliceBlue`,
    fontFamily: typography.options.headerFontFamily.join(`,`),
    fontSize: `0.8rem`,
    textDecoration:"none",
  },
  more: {
    color: `aliceBlue`,
    fontSize: `0.5rem`,
    marginLeft: `auto`,
    textDecoration: `none`,
  },
}

export default Footer

		 